@import 'Var';
.hero{
  max-width: 100vw;
  min-height: 100vh;
  background: linear-gradient(45deg,  $bg 70%, $secondary);
  overflow-x: hidden;
  &-banner{
    width: 100%;
    height: 70vh;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px){
      flex-direction: column-reverse;
    }
    &-title{
      @include center-column;
      flex: 1;
      width: 100%;
      @media screen and (max-width: 768px){
        flex: auto;
        justify-content: space-between;
      }
    }
    &-type {
      display: flex;
      flex-direction: row;
      & .hello {
        color: #fff;
        overflow: hidden;
        width: auto;
        margin: 0 auto;
        letter-spacing: 1px;
        animation: typing 1s steps(50, end);
        font-size: 50px;
        @media screen and (max-width: 768px){
          font-size: 25px;
          margin: 20px 0;
        }
      }
      & .col {
        display: flex;
        flex-direction: column;
        &-text {
          visibility: hidden;
          margin-top: 0px;
          animation: show 1s ease forwards 1.6s;
          color: #fff;
          font-size: 50px;
          @media screen and (max-width: 768px){
            font-size: 25px;
            margin: 20px 0;
          }
          & span {
            color: $primary;
          }
        }
        &-box {
          background: #555;
          color: white;
          width: auto;
          padding: 5px;
          border-radius: 5px;
          text-align: center;
          visibility: hidden;
          margin-top: 0;
          animation: prompt 1s ease 1s, promptBack 0.1s ease forwards 1.5s;
        }
      }
      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
      @keyframes prompt {
        from {
          visibility: hidden;
        }
        to {
          visibility: visible;
        }
      }
      @keyframes promptBack {
        from {
          background: #555;
        }
        to {
          background: $secondary;
        }
      }
      @keyframes show {
        from {
          visibility: hidden;
        }
        to {
          visibility: visible;
        }
      }
    }
    &-name{
      @include center-column;
      padding-left: 10px;
      cursor: pointer;
      margin-top: 20px;
      @media screen and (max-width: 768px){
        margin: 20px 0;
      }
    }
    &-buttons {
      @include center-row;
      & a {
        @include center-row;
        width: 100px;
        height: 50px;
        margin: 5px;
        background: $black;
        border-radius: 10px;
        border: solid 1px $primary;
        color: $primary;
        font-size: 25px;
        font-family: $font-family;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        z-index: 1;
        overflow: hidden;
        &::after{ 
          position: absolute; 
          content: ""; 
          top: 0; 
          width: 0; 
          height: 100%; 
          background: linear-gradient(61deg, $secondary 0%, $primary 100%); 
          transition: all 0.5s; 
          border-radius: inherit; 
          z-index: -1; 
        } 
        &:hover{ 
          color: #000; 
          &::after {
            width: 100%;
          }
        }
        &.button-cv::after{
          left: 0;
        }
        &.button-git::after{
          right: 0;
        }
      }
    }
    &-desc {
      width: 90%;
      height: auto;
      margin: auto;
      padding: 20px;
      color: $white;
      background: rgba(5, 5, 5, 0.1);
      border-radius: 10px;
      border: groove 1px rgba(255,255,255,.3);
      font-size: 20px;
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
    }
    &-logo{
      @include center-row;
      max-height: 100px;
      &-img{
        max-height: 50px;
      }
    }
  }
  &-frontend {
    @media screen and (max-width: 1024px){
      height: 75px;
    }
    @media screen and (max-width: 768px){
      height: 55px;
    }
    @media screen and (max-width: 576px){
      height: 45px;
    }
    & path {
      stroke: $primary;
    }
    & path:nth-child(1) {
      stroke-dasharray: 412;
      stroke-dashoffset: 412;
      animation: line-anim 1.5s ease forwards 2s;
    }
    & path:nth-child(2) {
      stroke-dasharray: 515;
      stroke-dashoffset: 515;
      animation: line-anim 1.5s ease forwards 2.5s;
    }
    & path:nth-child(3) {
      stroke-dasharray: 513;
      stroke-dashoffset: 513;
      animation: line-anim 1.5s ease forwards 3s;
    }
    & path:nth-child(4) {
      stroke-dasharray: 559;
      stroke-dashoffset: 559;
      animation: line-anim 1.5s ease forwards 3.5s;
    }
    & path:nth-child(5) {
      stroke-dasharray: 364;
      stroke-dashoffset: 364;
      animation: line-anim 1.5s ease forwards 4s;
    }
    & path:nth-child(6) {
      stroke-dasharray: 510;
      stroke-dashoffset: 510;
      animation: line-anim 1.5s ease forwards 4.5s;
    }
    & path:nth-child(7) {
      stroke-dasharray: 559;
      stroke-dashoffset: 559;
      animation: line-anim 1.5s ease forwards 5s;
    }
    & path:nth-child(8) {
      stroke-dasharray: 518;
      stroke-dashoffset: 518;
      animation: line-anim 1.5s ease forwards 5.5s;
    }
  }
  &-dev {
    width: 400px;
    margin-top: -30px;
    @media screen and (max-width: 1024px){
      height: 55px;
      margin-top: 0px;
    }
    @media screen and (max-width: 768px){
      height: 35px;
    }
    @media screen and (max-width: 576px){
      height: 30px;
    }
    & path {
      stroke: $secondary;
    }
    & path:nth-child(1) {
      stroke-dasharray: 518;
      stroke-dashoffset: 518;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(2) {
      stroke-dasharray: 510;
      stroke-dashoffset: 510;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(3) {
      stroke-dasharray: 440;
      stroke-dashoffset: 440;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(4) {
      stroke-dasharray: 510;
      stroke-dashoffset: 510;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(5) {
      stroke-dasharray: 332;
      stroke-dashoffset: 332;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(6) {
      stroke-dasharray: 513;
      stroke-dashoffset: 513;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(7) {
      stroke-dasharray: 428;
      stroke-dashoffset: 428;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(8) {
      stroke-dasharray: 510;
      stroke-dashoffset: 510;
      animation: line-anim 3s ease forwards 6s;
    }
    & path:nth-child(9) {
      stroke-dasharray: 515;
      stroke-dashoffset: 515;
      animation: line-anim 3s ease forwards 6s;
    }
  }
  &-image {
    flex: 1;
    margin: auto;
    & .square {
      position: relative;
      width: 400px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 38% 62% 63% 37%/ 41% 44% 56% 59%;
      background: rgba(104, 249, 231, 0.25);
      box-shadow: 0 0 10px $primary;
      transform: rotate(30deg);
      @media screen and (max-width: 768px){
        width: 100px;
        height: 100px;
      }
      & img {
        max-width: 90%;
        transform: rotate(-30deg);
        //filter: drop-shadow(0px 0px 10px $secondary);
      }
    }
    
  }
  &-next{
    @include center-column;
    width: 100%;
    &-icon{
      width: 50px;
      height: 50px;
      color: $white;
      animation: next 1s ease infinite;
      cursor: pointer;
    }
  }
  @keyframes next {
    0% {
      transform: translateY(10px);
      color: $white;
    }
    100% {
      transform: translateY(30px);
      color: $primary;
    }
  }
  @keyframes line-anim {
    to {
      stroke-dashoffset: 0;
      }
  }
  @keyframes fill {
    to {
      fill: $secondary;
      }
    }
  // &-desc{
  //   font-size: 25px;
  //   margin: 20px;
  //   color: $white;
  //   @media screen and (max-width: 768px){
  //     font-size: 20px;
  //   }
  //   @media screen and (max-width: 576px){
  //     font-size: 18px;
  //   }
  // }
}