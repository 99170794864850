.skills{
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $bg;
  @include center-column;
  &-circle{
    width: 600px;
    height: 600px;
    margin: 40px auto;
    margin-bottom: 70px;
    border: none;
    border-radius: 50%;
    background: transparent;
    border: 10px solid $primary;
    box-shadow: 0 0 10px $primary;
    position: relative;
    @media screen and (max-width: 768px){
      width: 300px;
      height: 300px;
    }
    @media screen and (max-width: 540px){
      width: 200px;
      height: 200px;
    }
    &-text{
      @include center-column;
      position: absolute;
      width: 400px;
      height: 400px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      border-radius: 400px;
      text-align: center;
      color: $white;
      background: $bg;
      z-index: 50;
      @media screen and (max-width: 768px){
      display: none;
      }
      & h3{
        color: $primary;
      }
      &-mobile{
        display: none;
        color: $white;
        @media screen and (max-width: 768px){
          @include center-column;
           width: 90%;
           height: auto;
           text-align: justify;
        }
        & h3{
          color: $primary;
        }
      }
    }
  }

  &-button{
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid $primary;
    background: $bg;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    z-index: 20;
    align-items: center;
    color: $primary;
    visibility: hidden;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    @media screen and (max-width: 540px){
      width: 50px;
      height: 50px;
      border: 3px solid $primary;
    }
    &.active{
      transition: 0.5s ease-in-out;
      visibility: visible;
      &:nth-last-child(2),
      &:nth-last-child(10),
      &:nth-last-child(18){
        top: -5px; //circle + border
        transition: 0.5s ease-in-out;
      }
      &:nth-last-child(3),
      &:nth-last-child(11),
      &:nth-last-child(19){
        top: calc(15% - 5px);
        left: 85%;
        transition: 0.5s ease-in-out;
        transition-delay: 0.1s;
      }
      &:nth-last-child(4),
      &:nth-last-child(12),
      &:nth-last-child(20){
        left: calc(100% + 5px);
        transition: 0.5s ease-in-out;
        transition-delay: 0.2s;
      }
      &:nth-last-child(5),
      &:nth-last-child(13),
      &:nth-last-child(21){
        top: calc(85% + 5px);
        left: 85%;
        transition: 0.5s ease-in-out;
        transition-delay: 0.3s;
      }
      &:nth-last-child(6),
      &:nth-last-child(14),
      &:nth-last-child(22){
        top: calc(100% + 5px);
        transition: 0.5s ease-in-out;
        transition-delay: 0.4s;
      }
      &:nth-last-child(7),
      &:nth-last-child(15),
      &:nth-last-child(23){
        top: calc(85% + 5px);
        left: calc(15% - 5px);
        transition: 0.5s ease-in-out;
        transition-delay: 0.5s;
      }
      &:nth-last-child(8),
      &:nth-last-child(16),
      &:nth-last-child(24){
        top: 50%;
        left: 0;
        transition: 0.5s ease-in-out;
        transition-delay: 0.6s;
      }
      &:nth-last-child(9),
      &:nth-last-child(17),
      &:nth-last-child(25){
        top: calc(15% - 5px);
        left: 15%;
        transition: 0.5s ease-in-out;
        transition-delay: 0.7s;
      }
      &:hover .skills-button-text{
        opacity: 1;
        color: $white;
      }
    }
    &-icon{
      font-size: 40px;
      @media screen and (max-width: 540px){
        font-size: 30px;
      }
    }
    &-text{
      position: absolute;
      top: 70px;
      font-weight: bold;
      opacity: 0;
      transition: all 0.5s ease-in;
      z-index: 100;
      @media screen and (max-width: 540px){
        font-size: 12px;
      }
    }
    
  }
  &-select{
    @include center-row;
    background: transparent;
    width: 100%;
    &-button{
      height: 150px;
      width: 33%;
      color: $primary;
      background: transparent;
      border: none;
      cursor: pointer;
      @media screen and (max-width: 768px){
        height: 100px;
      }
      @media screen and (max-width: 540px){
        height: 70px;
      }
      &:focus{
        outline: 0;
      }
      &:hover .skills-select-button-up,
      &:hover .skills-select-button-down{
        transition: transform 0.5s ease-in-out;
        transform: scale(1.1);
      }
      &:nth-child(2){
        background: $black;
      }
      &-up{
        font-size: 55px;
        color: $primary;
        margin-bottom: -30px;
        position: relative;
        @media screen and (max-width: 1250px){
          font-size: 45px;
          margin-bottom: -25px;
        }
        @media screen and (max-width: 768px){
          font-size: 30px;
          margin-bottom: -15px;
        }
        @media screen and (max-width: 540px){
          font-size: 18px;
          margin-bottom: -10px;
        }
        &.middle{
          color: $white;
        }
      }
      &-down{
        font-size: 45px;
        color: $secondary;
        @media screen and (max-width: 1250px){
          font-size: 35px;
        }
        @media screen and (max-width: 768px){
          font-size: 25px;
        }
        @media screen and (max-width: 540px){
          font-size: 15px;
        }
        &.middle{
          color: rgba(255,255,255,.75)
        }
      }
      &-circle{
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $primary;
        box-shadow: 0 0 10px $primary;
        top: 50%;
        left: 5%;
        @media screen and (max-width: 1250px){
          width: 30px;
          height: 30px;
        }
        @media screen and (max-width: 998px){
          display: none;
        }
        &.middle{
          background: $white;
          box-shadow: 0 0 10px $white;
        }
      }
    }
  }
  
}

