//@import compass


// n is number of stars required
@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF'; }

  @return unquote($value); }

$shadows-small:  multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big:    multiple-box-shadow(100);

.projects {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%); }

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation			: animStar 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 0px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small; } }

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation			: animStar 75s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 0px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium; } }

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation			: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 0px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big; }


  span {
    background: -webkit-linear-gradient(white, #38495a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; } }

@keyframes animStar {
  from {
    transform: translateY(0%); }
  to {
    transform: translateY(100vh); } }

