@import "Var";

$backgrounds: (
  axoffice: linear-gradient(45deg,#44d17a 0,  $bg 30%, $black 70%, #44d17a 100%),
  cakeme: linear-gradient(45deg,#DC934F 0,  $bg 30%, $black 70%, #DC934F 100%),
  neograd: linear-gradient(45deg,#6F0DD0 0,  $bg 30%, $black 70%, #6F0DD0 100%),
  aleague: linear-gradient(45deg,#A2FA92 0,  $bg 30%, $black 70%, #2B9CDB 100%),
  xtech: linear-gradient(45deg,#2CEAB9 0,  $bg 30%, $black 70%, #602CEA 100%),
  sportstory: linear-gradient(45deg,#00FFFF 0,  $bg 30%, $black 70%, #0000FF 100%),
  multiwords: linear-gradient(45deg,#fe2be9 0,  $bg 30%, $black 70%, #5169F0 100%),
  kim: linear-gradient(45deg,#EFF1F3 0,  $bg 30%, $black 70%, #EFF1F3 100%),
  galaxychat: linear-gradient(45deg,#35D6EF 0,  $bg 30%, $black 70%, #35D6EF 100%),
  clickscore: linear-gradient(45deg,#2A0FD7 0,  $bg 30%, $black 70%, #2A0FD7 100%),
  clocks: linear-gradient(45deg,#22daf3 0,  $bg 30%, $black 70%, #22daf3 100%),
  rounder: linear-gradient(45deg,#0D1B34 0, $bg 30%, $black 70%, #0D1B34 100%),
);

.projects{
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $bg;
  overflow-x: hidden;
  &-section {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &-nav{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    max-height: 100vh;
    width: 200px;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: row;
      overflow-x: scroll;
    }
    &-list {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        flex-direction: row;
      }
    }
    &-box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 200px;
      height: 70px;
      margin: 10px;
      padding: 5px;
      background: rgba(184, 172, 172, 0.25);
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
      color: $white;
      border-radius: 10px;
      cursor: pointer;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        width: 150px;
        height: 50px;
      }
    //hover to line box
    & h3 {
      font-size: 20px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    & h5 {
      color: gray;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    &.active {
      box-shadow: 0 0 10px aqua;
    }
    &:hover{
      box-shadow: 0 0 10px aqua;
      transition-delay: 400ms;
      & span:nth-child(1){
        left: 100%;
        transition: 0.4s;
      }
      & span:nth-child(3) {
        right: 100%;
        transition: 0.4s;
        transition-delay: 0.2s;
      }
      & span:nth-child(2) {
        top: 100%;
        transition: 0.4s;
        transition-delay: 0.1s;
      }
      & span:nth-child(4) {
        bottom: 100%;
        transition: 0.4s;
        transition-delay: 0.3s;
      }
    }
    & span{
      position: absolute;
      display: block;
      &:nth-child(1) {
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, aqua);
      }
      &:nth-child(3) {
        bottom: 0;
        right: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(270deg, transparent, aqua);
      }
      &:nth-child(2) {
        top: -100%;
        right: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(180deg, transparent, aqua);
      }
      &:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(360deg, transparent, aqua);
      }
    }
    }
  }
  &-info {
    flex: 3;
    //background: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &-box {
      width: 600px;
      background: transparent;
      border-radius: 10px;
      color: $white;
      padding: 20px;
      text-align: justify;
      margin-left: 50px;
      @media screen and (max-width: 768px) {
        width: 95%;
        margin: auto;
      }
      & h4 {
        text-transform: uppercase;
        font-size: 14px;
        color: #aaa;
        margin: 10px auto;
        text-decoration: underline;
      }
      & p {
        font-size: 18px;
      }
    }
    &-stack {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      padding: 10px;
      width: 100%;
      height: 70px;
      box-shadow: 0 8px 32px 0 rgba(109, 113, 170, 0.37);
      border-radius: 10px;
      &-button{
        @include center-column;
        position: relative;
        transition: 2.5s ease-in-out;
        width: 30px;
        height: 30px;
        margin: auto 10px;
        cursor: pointer;
        &:hover{
          & .projects-info-stack-button-icon{
            color: $primary;
            top: -10px;
            z-index: 2;
            transition: all 0.5s ease-in-out;
          }
          & .projects-info-stack-button-name{
            visibility: visible;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            transition: all 0.5s ease-in-out;
            color: $primary;
          }
        }
        &-icon{
          position: absolute;
          top: 0;
          left: 0;
          color: $white;
          transition: all 0.5s ease-in-out;
        }
        &-name{
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.5s ease-in-out;
          visibility: hidden;
          color: $white;
        }
      }
    }
  }
  &-carousel {
    @include center-row;
    justify-content: flex-start;
    padding: 20px;
    height: 100%;
    //background: green;
    
    &-image {
      transform: skewX(5deg) rotate(-5deg);
      border-radius: 10px;
      box-shadow: 0 8px 32px 0 rgba(109, 113, 170, 0.9);
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px ); 
      margin: 20px 0;
      transition: 1s ease-in-out;
      position: relative;
      @media screen and (max-width: 768px) {
        border-radius: 5px;
        box-shadow: 0 4px 16px 0 rgba(109, 113, 170, 0.9);
      }
      &-desktop {
        max-width: 500px;
        border-radius: 10px;
        @media screen and (max-width: 768px) {
          max-width: 90%;
        } 
      }
      &-mobile {
        position: absolute;
        right: -30px;
        max-height: 250px;
        transform: skewX(-10deg) rotate(10deg);
        border-radius: 10px;
        box-shadow: 0 8px 32px 0 rgba(109, 113, 170, 0.9);
        @media screen and (max-width: 768px) {
          max-height: 150px;
          border-radius: 5px;
          box-shadow: 0 4px 16px 0 rgba(109, 113, 170, 0.9);
        }
        @media screen and (max-width: 576px){
          max-height: 100px;
        }
      }
    }
    &-button {
      padding: 10px;
      outline: none;
      background: transparent;
      border: none;
      color: $primary;
      cursor: pointer;
      outline: none;
      &:disabled {
        color: gray !important;
      }
    }
    &-icon {
      width: 48px;
      height: 48px;
    }
  }
  // change background for project (on the end to not distrub earlier class)
  @each $key, $val in $backgrounds {
    &.#{$key} {
      background: $val;
    }
  }
}