@import 'Var';
*, *::after, *::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background: $bg;
  font-family: $font-family;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}
.container{
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
  transition: all 0.5s ease-in-out;
  transition-delay: 1.25s;
  &.margin{
   margin-left: 50px;
   max-width: calc(100vw - 50px);
  transition: all 0.5s ease-in-out;
  } 
}

.section-title{
  @include center-column;
  width: 100%;
  &-text{
    font-size: 50px;
    color: $white;
    padding: 10px;
    @media screen and (max-width: 768px){
      font-size: 35px;
    }
    @media screen and (max-width: 576px){
      font-size: 30px;
    }
  }
  &-line {
    width: 300px;
    height: 3px;
    background: linear-gradient(90deg, transparent, $primary, transparent);
    @media screen and (max-width: 768px){
      width: 250px;
    }
    @media screen and (max-width: 576px){
      width: 200px;
    }
  }
  &.next{
    padding-top: 20px;
  }
}