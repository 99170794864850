@import 'Var';
.about {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: black;
  border-top: 5px groove $primary;
  padding-bottom: 200px;
  overflow-x: hidden;
  &-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 80vh;
    overflow: hidden;
    position: relative;
  }
  &-boxin {
    width: 500px;
    height: 600px;
    background: transparent;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    position: relative;
    &-shadow {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 200px;
      background: linear-gradient(to right, transparent, black)
    }
  }
  &-title {
    position: absolute;
    top: 0;
    left: -1500px;
    &-box{
      display: flex;
      flex-direction: row;
    }
    &-div {
      display: flex;
      justify-content: flex-start;
      width: 500px;
      font-size: 100px;
      color: $secondary;
      @media screen and (max-width: 768px){
        font-size: 70px;
      }
    }
  }
  &-description{
    position: relative;
    width: 100%;
    padding: 50px 0;
    margin-top: -50px;
    overflow: scroll;
    cursor: ns-resize;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &-box {
      width: 700px;
      min-height: 500px;
      padding: 20px;
      background: rgba(0,0,0, 0.1);
      border-radius: 20px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 768px){
        margin-left: 10px;
        max-width: 90%;
      }
    }
    &-text {
      font-size: 20px;
    }
  }
}