.contact{
  position: relative;
  width: 100%;
  min-height: 100vh;
  border-top: 5px groove $primary;
  background: transparent;
  &-section {
    @include center-row;
    width: 90%;
    margin: 20px auto;
    justify-content: space-around;
    background: rgba(0,0,0,.25);
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    position: relative;
    @media screen and (max-width: 768px) {
      @include center-column;
      width: 95%;
    }
    &-left {
      @include center-column;
      padding: 20px;
    }
  }
  &-image {
    max-width: 300px;
    max-height: 300px;
    @media screen and (max-width: 768px) {
      max-width: 200px;
      max-height: 300px;
    }
  }
  &-form{
    @include center-column;
    height: 400px;
    background: transparent;
    & > input{
      width: 300px;
      height: 40px;
      margin: 10px auto;
      padding: 0 10px;
      background: rgba(255,255,255,.25);
      border-radius: 10px;
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
      border: none;
      color: $white;
      font-family: $font-family;
      transition: height 0.5s ease-in-out;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      &:focus{
        color: $black;
        background: $primary;
        transition: 0.5s ease-in-out;
        outline: none;
      }
    }
    & > label{
      color: $primary;
    }
    & > textarea{
      width: 300px;
      height: 80px;
      padding: 10px;
      background: rgba(255,255,255,.25);
      border-radius: 10px;
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
      border: none;
      color: $white;
      font-family: $font-family;
      transition: height 0.5s ease-in-out;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      &:focus{
        color: $black;
        background: $primary;
        height: 100px;
        transition: 0.5s ease-in-out;
        outline: none;
      }
    }
    &-button{
      width: 150px;
      height: 50px;
      margin: 20px auto;
      border-radius: 50px;
      border: none;
      color: $bg;
      font-size: 20px;
      background: linear-gradient(to left, $primary, $secondary);
      cursor: pointer;
      position: relative;
      overflow: hidden;
      &::after{
        position: absolute;
        content: '';
        top: 0;
        left: -20px;
        height: 50px;
        width: 20px;
        transform: skew(20deg);
        background: rgba(255,255,255,.7);
        transition: 0.5s ease-in-out;
        z-index: -1;
      }
      &:hover::after{
        left: 100%;
        transition: 0.5s ease-in-out;
      }
    }
  }
  &-icons{
    width: 100%;
    @include center-row;
    position: absolute;
    bottom: -45px;
    &-item{
      color: $primary;
      font-size: 70px;
      margin: 20px;
      cursor: pointer;
      transition: 0.25s ease-in-out;
      @media screen and (max-width: 768px) {
        font-size: 40px;
      }
      &:hover{
        color: $white;
        transition: 0.25s ease-in-out;
      }
    }
  }
  &-footer{
    @include center-row;
    margin-top: 40px;
    width: 100%;
    height: 40px;
    background: $black;
    color: $white;
    text-align: center;
  }
}