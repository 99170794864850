@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

$font-family: 'Ubuntu', sans-serif;
$primary: rgba(104, 249, 231, 1);
$secondary: rgba(104, 249, 231, 0.5);
$white: #ffffff;
$black: #111111;
$bg: #030323;

@mixin center-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@mixin center-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}