@import 'Var';
.toggler{
  position: fixed;
  top: 5px;
  left: 10px;
  background: transparent;
  z-index: 10;
  &-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin: 10px auto;
    background: transparent;
    color: $primary;
    border: none;
    position: relative;
    transition: 2s ease-in-out;
    cursor: pointer;
    &::before{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 35px;
      height: 35px;
      background: $bg;
      border: 2px solid $primary;
      transform: rotate(45deg);
      z-index: -1;
    }
    &.active{
      transform: rotate(180deg);
      transition: 1s ease-in-out;
    }
    &:focus{
      outline: none;
    }
  }
}
.nav{
  height: 100vh;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: $black;
  transition: 0.5s ease-in-out;
  &.hide{
    transform: translateX(-100%);
    transition: 0.5s ease-in-out;
    transition-delay: 1s;
    & .nav-link{
        transform: translateX(-100%);
        transition: 0.5s ease-in-out;
        opacity: 0;
      &:nth-child(1){
        transition-delay: 1s;
      }
      &:nth-child(2){
        transition-delay: 0.75s;
      }
      &:nth-child(3){
        transition-delay: 0.5s;
      }
      &:nth-child(4){
        transition-delay: 0.25s;
      }
    }
  }
  // &-avatar{
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 40px;
  //   background: #555555;
  // }
  &-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 80vh;
  }
  &-link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: rotate(270deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    &:nth-child(1){
      transition-delay: 0.25s;
    }
    &:nth-child(2){
      transition-delay: 0.5s;
    }
    &:nth-child(3){
      transition-delay: 0.75s;
    }
    &:nth-child(4){
      transition-delay: 1s;
    }
    &:hover{
      & .nav-link-line{
        background: $white;
        box-shadow: 0 0 5px $white;
        transition: 0.5s ease-in-out;
      }
    }
    &.active{
      color: $primary;
      transition: 0.5s ease-in-out;
      & .nav-link-line{
        background: $white;
        box-shadow: 0 0 5px $white;
        transition: 0.5s ease-in-out;
      }
    }
    &-line{
      width: 5px;
      height: 40px;
      margin: 10px;
      background: $primary;
      box-shadow: 0 0 5px $primary;
      transition: 0.5s ease-in-out;
    }
    &-text{
      font-size: 30px;
      font-weight: 400;
      color: $white;
      @media screen and (max-height: 700px){
        font-size: 25px;
      }
      @media screen and (max-height: 600px){
        font-size: 20px;
      }
      &:hover{
        color: $primary;
        transition: 0.5s ease-in-out;
        text-shadow: 0 0 5px $primary;
      }
    }
  }
}